import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Typography } from "@mui/material"

const CosasQueNoSeDebenDecir = () => (
  <Layout>
    <Seo title="Cosas que no se deben decir" />
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `0 1.0875rem 1.45rem`,
      }}
    >
      <Typography variant="h4">
        Chapter ##: Cosas Que No Se Deben Decir
      </Typography>
      <br />
      <Typography variant="body2" paragraph>
        Milenio sat in the cheap plastic chair, the weak legs bending under even
        her negligible weight. Her arms, sweaty, rested nervously on the Corona
        branded plastic table. A table bordering on waxy, it was a wonder that
        it had not yet melted in the sun. The idea amused her. Her chest grew
        lighter as the frivolous thought distracted her. She almost yelped when
        someone placed a giant styrofoam cup of horchata in front of her, a
        splash of off-white liquid hitting the blue surface of the table as the
        ice inside the cup clinked rhythmically. She looked up and saw that
        smirking face she had come to love so much.
      </Typography>
      <Typography variant="body2" paragraph>
        “You order food yet?” He asked. She shook her head excitedly as he sat
        down across the table. “I didn’t think you’d be here.” He said, sounding
        tired. His eyes were closed, his posture relaxed. “Of course! Except for
        Christmas to New Year’s, I’ve been here for breakfast every Friday.” She
        beamed proudly. “Just like we used to.” He noted. “Just like we used
        to.” She agreed. “I’m sorry I didn’t call. My landline is still
        disconnected.” Her best friend looked guilty for a second. “Oh, shut up.
        Just tell me all about your big adventure!” She leaned forward eagerly,
        using her hand to signal the staff to bring the usual.
      </Typography>
      <Typography variant="body2" paragraph>
        Their conversation carried on over steaming plates of cochinita.
        Mercado, he went by his family name even to her, had spent the winter
        driving trucks in the U.S. He had been driving ever since they got out
        of high school, about 8 years ago already. But this was his first year
        driving internationally. “Yeah, I never expected to get that
        recommendation from Don Chivo,” he started. “But man, they liked me. I
        think I might take them up every winter.” From just a couple months
        there he had apparently made way more than he did in a year of work in
        the country. Although those drives only took him away for a few days at
        a time, this absence had been eternal. Milenio felt selfish protesting
        but she still let him know how much she had missed him. He laughed. “Oh
        come on, you’re fine. You don’t really need me that much.”
      </Typography>
      <Typography variant="body2" paragraph>
        Their chatting died down for a minute. Both of them, considerate, made a
        point to show how much they were enjoying their food. “You can’t find
        anything like this over there.” Mercado declared. “I don’t think they
        can get the stoves hot enough to cook something like this in Alaska.”
        She said, with a giggle. “Oh, I wasn’t in Alaska.” He said dryly. “Oh.”
        She recoiled slightly. The hint of a smile returned to his face, the icy
        look had only lasted a second. “Yeah, no, they need the experienced
        drivers for that so the people who usually cover the midwest routes take
        over the increased workload and us temps pick up their usual
        assignments.” He clarified between mouthfuls. He tried to make up for
        the attitude. “You know, the food is different but still good.” She
        looked up, interested. He continued, “pasties were my favorite. It’s
        really just empanadas but the fillings taste very different.” She looked
        at him, eyes filled with curiosity. “Different how?” She asked. “I’m not
        even going to try to describe it, I can’t think of anything we have here
        that’s in any way like what they have there.”
      </Typography>
      <Typography variant="body2" paragraph>
        He wiped a bead of sweat off his face. Milenio felt a familiar pang in
        her stomach. It was always painful to see his index finger. There was
        nothing wrong with it, but he always kept that finger nail painted.
        Always the same color. A glittery blue. It clashed with the whole rest
        of his look. Sturdy jeans, work boots, always a white shirt that started
        the day pristine and found some way to get inexplicably dirty. She never
        said anything about it. She respected what it meant to him. Even if it
        brought her bad memories. Trying to put the thoughts away she changed
        the topic. “Don’t you want to take off the jacket? It looks super cool
        but you must be melting!” She exclaimed. He let out a chuckle. “Oh Hell,
        you’re right.” He stood up for a minute to remove the garment. It was a
        huge jacket, maybe a size too big for him. “I just got too used to it
        over there, I put it on on auto-pilot.” He laid it over his chair and
        sat back down, not before flashing the back to Milenio. It was
        emblazoned with the logo of his Winter employer. Utani Trucking Company.
        He was obviously proud of it. Milenio considered for a second that he
        must have worn it on purpose, just so he could have an excuse to show
        her. She smiled at the thought. He was always sweet in small ways like
        that.
      </Typography>
      <Typography variant="body2" paragraph>
        They finished their meal and walked over to the nearby bus stop. Their
        whole working lives they had been fortunate enough to have most Fridays
        free. While they got together for breakfast, they had an understanding
        that the rest of the day was for working on their personal projects. She
        worked on her journalism, while Mercado worked on his sculptures.
        “Saul…” She called his attention using his first name. He turned from
        the street to look down at her. She was smiling up at him, her face
        slightly flush. “Thank you for coming to see me.” Before he could say
        anything she had her arms around him. She had missed those hugs so much.
        His arms were always soft, his chest was always warm. Something was
        wrong. Where there was once comfort and safety, there was now a firm
        coldness. He felt tense. She could tell, his whole body felt petrified,
        rough instead of welcoming. It was worse every passing second. Every
        instant that he didn’t raise his arms to hug her back, she felt farther
        and farther away from him. When she let go the bare inches of pavement
        between them spanned galaxies.{" "}
      </Typography>
      <Typography variant="body2" paragraph>
        There is something very reassuring about seeing your reflection in the
        eyes of someone who loves you. For as long as she had known him, Milenio
        had always been able to find a bright glimmer in his eyes when he looked
        at her. She could see her own light in them. This time they were burning
        a hole through her, staring at the emptiness behind her. She was right
        in front of him, and still, she could tell he could not see her.
      </Typography>
      <Typography variant="body2" paragraph>
        “No. You can’t touch me anymore.” His words were heavy, like lead
        anchors, firmly grounding them both back into reality. “Please, don’t
        touch me again.” He said. She fought back tears. She had no right to
        cry. She knew that much. Her apology was cut off by his departure. “See
        you next week.” He said as he walked away. He didn’t look angry. He
        didn’t look sad. That was the scariest part. He didn’t look like he was
        alive. She had done that to him. And she had just made it worse. She
        hadn’t thought about it. In her mind she was back in those days when it
        was them against the world. Those times had been longer. They had lasted
        years. Why did just a few months have to ruin that?
      </Typography>
      <Typography variant="body2" paragraph>
        She was left alone by the bus sign. She had felt invisible moments
        before, and now she was exposed. She didn’t know if the people at the
        taco stand had noticed the scene, but in her heart they had heard every
        word of it. She crossed her arms over her chest, subtly and covertly
        hugging herself. There, in the hot morning sun, she could feel ice on
        her skin. That familiar chill that so often haunted her. In the bright
        light of day, under the clear blue sky, she faced a storm. It froze her
        to her core. She held it back as she always did. She masked her shivers
        by tapping her foot, signaling impatience in waiting. She looked out
        into the horizon, her eyes focused, her mind trying to manifest a bus
        heading to Carpintero in the spot where the sky meets the street. She
        couldn’t tell you how long it took, but she held her breath the whole
        time. In handing the fare to the driver she was brought back, the
        micro-interaction pulling her out from the dark blizzard of her
        thoughts. She made her way down to a seat by the back exit. The sight of
        the half empty vehicle calmed her down, she was going home. She let out
        a huge sigh, deflating into her seat. She looked out the window, hoping
        to see Mercado walking along the sidewalk. She didn’t. She was out of
        the cold, but now her mind raced. She was going to see him next week.
        Should she? Why? Wasn’t she just hurting him more? Wasn’t he hurting
        her?
      </Typography>
      <Typography variant="body2" paragraph>
        The pace of her thoughts seemed to match the pace of the bus. Rushing.
        Stopping. Rushing. She went through the same line of reasoning she had
        revisited the whole year. She shouldn’t have said yes. Everything would
        still be the same if she had said no again. It wasn’t his fault. He had
        asked again when she felt weak, but in the end it had been her who went
        along with it, right? No, he should have known. He shouldn’t have let
        himself take advantage of her. But wasn’t he in his right to try to get
        what he wanted? He had said it’d be fine if they decided to go back on
        it, but then it hadn’t been. Sometimes it was just that, fine, but other
        times it was terrifying. What had happened that she now so often felt
        scared of someone she loved so much?
      </Typography>
      <Typography variant="body2" paragraph>
        She got off a few stops too early. Right by the lagoon. She sat down on
        the grass, staring at the water. She wasn’t going to get any writing
        done that day.
      </Typography>
    </div>
  </Layout>
)

export default CosasQueNoSeDebenDecir
